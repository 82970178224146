
.wrapper svg{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .wrapper svg circle{
    stroke: #3e8e41;
  }
  .circle{
    transform-origin: center ;
  }
  #arc4{
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: 6s 1s rota infinite linear reverse;
  }
  #arc{
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
  }
  #arc6{
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: 3s 1s rota infinite linear alternate;
  }
  #arc5{
    stroke-dasharray: 400;
    stroke-dashoffset: 400;
    animation: 2s 1s rota infinite linear reverse;
}

 @keyframes rota{
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }